const Footer: React.FC = () => {
  return (
    <>
      <div className="p-5 flex mx-32">
        {/* <span className="flex-1">
          <img
            src="/assets/logo.svg"
            className="w-10 text-extrabold  "
            alt="logo"
          />
        </span> */}
        <div className="flex-1"></div>

        <div className="flex-1"></div>

        <div className="flex-1"></div>
      </div>
      <div className="p-5 flex mx-32 mt-80">
        <div className="flex-1 text-center">© 2021 THRBL</div>
      </div>
    </>
  )
}

export default Footer
