import { IonContent, IonPage } from '@ionic/react'
import { useParams } from 'react-router'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import './Home.css'

const Home: React.FC = () => {
  const { name } = useParams<{ name: string }>()

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="mt-40 text-8xl font-extrabold text-center my-auto">
          THRBL
        </div>

        <Footer></Footer>
      </IonContent>
    </IonPage>
  )
}

export default Home
